<template>
  <div v-if="invoice">
    <div v-if="hasClose">
      <form-builder :schema="schema" />

      <div class="text-center q-mt-sm">
        <q-btn
          color="dark"
          text-color="white"
          no-caps
          class="q-mr-sm"
          :label="$t('Discard')"
          @click="cancel"
        />

        <q-btn
          color="light-blue-9"
          no-caps
          :label="$t('Save')"
          @click="save"
        />
      </div>
    </div>

    <div v-else>
      <div class="row items-center">
        <q-input
          dense
          filled
          bg-color="white"
          color="black"
          standout="text-black"
          class="col text-black"
          :model-value="invoice.value"
          :label="$t('Enter amount')"
          @change="handleUpdate"
        >
          <template v-slot:append>
          </template>
        </q-input>

        <q-btn
          color="green-6"
          class="q-ml-sm"
          :label="$t('Create')"
          no-caps
          @click="save"
        />
      </div>

      <div
        v-if="message"
        class="q-pa-sm text-caption text-white"
      >
        {{ $t(message) }}
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Invoice',
  emits: ['submit', 'cancel'],
  props: {
    hideAccount: {
      type: Boolean,
      default () {
        return false
      }
    },
    hasClose: {
      type: Boolean,
      default () {
        return false
      }
    },
    id: {
      type: Number,
      default () {
        return null
      }
    },
    account: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      accountError: null,
      message: null
    }
  },
  computed: {
    ...mapGetters([
      'invoice'
    ]),
    schema () {
      const groups = [
        {
          styleClasses: 'row justify-end',
          fields: [
            {
              if: !this.hideAccount,
              type: 'multiselect',
              label: this.$t('Account'),
              value: this.invoice._embedded.account,
              wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
              disabled: !!this.account,
              error: this.accountError,
              required: true,
              atTheEnd: (row) => {
                if (row && row.type === 'payment') {
                  return {
                    label: this.$t('CoD'),
                    color: 'light-blue-3'
                  }
                }

                if (row) {
                  return {
                    label: this.$t(row.type),
                    color: 'orange-2'
                  }
                }

                return null
              },
              customLabel (row) {
                if (row && typeof row === 'object') {
                  return `${row.name} (${row.id})`
                }

                return row
              },
              onScroll: (search, page) => {
                const query = {
                  per_page: 25,
                  page,
                  search,
                  filter: [
                    { type: 'in', field: 'state', values: ['active','blocked'] }
                  ]
                }

                return this.$service.accounts.getAll(query)
                  .then(data => {
                    if (!search && data.items.length <= 0) {
                      this.accountError = 'To create an invoice you should have an account! You don\'t have any accounts yet!'
                      this.addErrorNotification(this.accountError)
                    }

                    return data
                  })
              },
              onChange: (account) => {
                this.updateInvoiceEmbedded({
                  account,
                  payer: account._embedded.customer,
                  payee: account._embedded.contractor
                })
              }
            },
            {
              type: 'input',
              inputType: 'number',
              value: this.invoice.value,
              label: this.$t('Enter amount'),
              wrapperStyleClasses: 'col q-pa-xs',
              required: true,
              onChange: value => {
                this.handleUpdate(value)
              }
            }
          ]
        }
      ]

      return { groups }
    }
  },
  mounted () {
    if (this.id && !this.invoice) {
      return this.loadInvoice(this.id)
    }

    if (!this.id) {
      this.setNewInvoice()

      if (this.account) {
        this.updateInvoice({
          _embedded: {
            ...this.invoice._embedded,
            account: this.account,
            payer: this.account._embedded.customer,
            payee: this.account._embedded.contractor
          }
        })
      }
    }
  },
  methods: {
    ...mapActions([
      'loadInvoice',
      'saveInvoice'
    ]),
    ...mapMutations([
      'setNewInvoice',
      'updateInvoice',
      'updateInvoiceEmbedded',
      'upsertInvoice',
      'addErrorNotification'
    ]),
    handleUpdate (value) {
      this.updateInvoice({ value: Number(value || 0).toFixed(2) })
    },
    cancel () {
      this.$emit('cancel')
    },
    save () {
      if (Number(this.invoice.value) <= 0) {
        this.message = 'Please enter the amount and then tap on add button.'
        this.addErrorNotification('Please enter the amount and then tap on add button.')
        return
      }

      return this.saveInvoice()
        .then(item => {
          this.upsertInvoice(item)
          this.$emit('submit', item)
          this.message = 'Success! The invoice has been created!'
        })
        .catch(err => {
          this.message = err.message
        })
    }
  }
}
</script>
